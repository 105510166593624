import styled from '@emotion/styled'
import anchoTheme from '@lib/theme'

export const FooterWrapper = styled.footer(
  {
    padding: '30px 0',
  },
  ({ theme = anchoTheme }) => ({
    backgroundColor: theme.colors.black,
    color: theme.colors.light,
  }),
)
