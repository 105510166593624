import styled from '@emotion/styled'
import anchoTheme from '@lib/theme'

const Icon = styled.div(
  {
    alignItems: 'center',
    borderRadius: '50%',
    display: 'flex',
    height: '120px',
    justifyContent: 'center',
    margin: '0 auto 15px',
    width: '120px',
  },
  ({ theme = anchoTheme }) => ({
    backgroundColor: theme.colors.black,
    color: theme.colors.primary,
  }),
)

export default Icon
