import styled from '@emotion/styled'
import anchoTheme, {
  FlexAlign,
  FlexDirection,
  FlexJustify,
  FlexWrap,
  ThemeColor,
  ThemeSize,
} from '@lib/theme'

interface ModuleProps {
  color?: ThemeColor
  hero?: boolean
  flexAlign?: FlexAlign
  flexDirection?: FlexDirection
  flexJustify?: FlexJustify
  flexWrap?: FlexWrap
  size?: ThemeSize
  slantBottom?: boolean
  slantTop?: boolean
}

const Module = styled.section<ModuleProps>(
  {
    display: 'flex',

    '&:after': {
      content: '""',
      left: 0,
      position: 'absolute',
      right: 0,
      width: '100%',
    },

    '&:before': {
      content: '""',
      position: 'absolute',
      width: '100%',
    },
  },
  ({
    color = ThemeColor.White,
    flexAlign = FlexAlign.Center,
    flexDirection = FlexDirection.Row,
    flexJustify = FlexJustify.Center,
    flexWrap = FlexWrap.Wrap,
    hero = false,
    size = ThemeSize.Medium,
    slantBottom = false,
    slantTop = false,
    theme = anchoTheme,
  }) => ({
    alignContent: flexAlign,
    alignItems: flexAlign,
    backgroundColor: theme.colors[color],
    color: [ThemeColor.Light, ThemeColor.White].includes(color)
      ? theme.colors.dark
      : theme.colors.light,
    flexDirection,
    flexWrap,
    justifyContent: flexJustify,
    justifyItems: flexJustify,
    marginBottom: slantBottom ? '88px' : 0,
    padding:
      size === ThemeSize.Large
        ? '90px 0'
        : size === ThemeSize.Medium
        ? '60px 0'
        : '30px 0',
    paddingBottom: slantTop
      ? size === ThemeSize.Large
        ? '180px'
        : size === ThemeSize.Medium
        ? '150px'
        : '120px'
      : size === ThemeSize.Large
      ? '90px'
      : size === ThemeSize.Medium
      ? '60px'
      : '30px',
    paddingTop:
      size === ThemeSize.Large
        ? hero
          ? '165px'
          : '90px'
        : size === ThemeSize.Medium
        ? hero
          ? '135px'
          : '60px'
        : hero
        ? '105px'
        : '30px',
    position: 'relative',

    '&:after': {
      backgroundColor: theme.colors[color],
      bottom: '-89px',
      clipPath: 'polygon(0% 0%, 100% 100%, 100% 0%)',
      display: slantBottom ? 'block' : 'none',
      height: '90px',
    },

    '&:before': {
      backgroundColor: theme.colors[color],
      clipPath: 'polygon(0% 0%, 0% 100%, 100% 100%)',
      display: slantTop ? 'block' : 'none',
      height: '90px',
      top: '-89px',
    },

    'h1, h2, h3, h4, h5': {
      color: [ThemeColor.Light, ThemeColor.White].includes(color)
        ? theme.colors.black
        : theme.colors.white,
    },
  }),
)

export default Module
