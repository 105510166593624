import styled from '@emotion/styled'
import anchoTheme from '@lib/theme'
import { rgba } from 'polished'

export const CodeBlock = styled.div(
  {
    display: 'block',
    padding: '20px',
    borderRadius: '10px',
    color: 'white',
    fontFamily: 'monospace',
    fontSize: '14px',
    overflow: 'auto',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    margin: '20px 0',
  },
  ({ theme = anchoTheme }) => ({
    backgroundColor: theme.colors.black,
    boxShadow: `0 4px 8px 0 ${rgba(theme.colors.black, 0.2)}`,
  }),
)
