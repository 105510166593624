import { FC, PropsWithChildren, useEffect, useState } from 'react'
import { ContentWrapper, PageWrapper } from './style'
import { ThemeProvider } from '@emotion/react'
import anchoTheme, { ThemeColor } from '@lib/theme'
import Header from './header'
import Footer from './footer'
import { useStytchB2BClient } from '@stytch/nextjs/b2b'
import { AnchoContextProvider } from '@context/ancho'
import { IAnchoContext } from 'types'

interface PageProps extends PropsWithChildren {
  headerContentColor?: ThemeColor
}

const Page: FC<PageProps> = ({ children, headerContentColor }) => {
  const stytchClient = useStytchB2BClient()

  const [anchoContext, setAnchoContext] = useState<IAnchoContext>(null)

  const loadAnchoContext = async () => {
    const organization = await stytchClient.organization.get()
    const user = await stytchClient.self.get()

    const anchoContext: IAnchoContext = {}

    if (organization) {
      anchoContext.organization = organization
    }

    if (user) {
      anchoContext.user = user
    }

    console.log(`Updating ancho context:`, anchoContext)

    setAnchoContext(anchoContext)
  }

  const updateAnchoContext = (newContext: Partial<IAnchoContext>) => {
    setAnchoContext({ ...(anchoContext || {}), ...newContext })
  }

  useEffect(() => {
    if (stytchClient) {
      loadAnchoContext()
    }
  }, [stytchClient])

  return (
    <ThemeProvider theme={anchoTheme}>
      <AnchoContextProvider value={anchoContext}>
        <PageWrapper>
          <Header contentColor={headerContentColor} />
          <ContentWrapper>{children}</ContentWrapper>
          <Footer />
        </PageWrapper>
      </AnchoContextProvider>
    </ThemeProvider>
  )
}

export default Page
