import styled from '@emotion/styled'
import anchoTheme from '@lib/theme'
import Link from 'next/link'

export const HeaderLink = styled(Link)({
  textDecoration: 'none',
})

interface HeaderWrapperProps {
  scrolled?: boolean
}

export const HeaderWrapper = styled.header<HeaderWrapperProps>(
  {
    backgroundColor: 'transparent',
    height: '75px',
    left: 0,
    padding: '15px 0',
    position: 'fixed',
    top: 0,
    transition: 'background-color 0.3s ease',
    width: '100%',
    zIndex: 100,
  },
  ({ scrolled, theme = anchoTheme }) => ({
    backgroundColor: scrolled ? theme.colors.white : 'transparent',
  }),
)

export const Logo = styled.img({
  maxHeight: '45px',
})
