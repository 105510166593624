import styled from '@emotion/styled'
import anchoTheme from '@lib/theme'

export enum ParagraphColor {
  Dark = 'dark',
  Grey = 'grey',
  Light = 'light',
}

interface PProps {
  color?: ParagraphColor
  small?: boolean
}

export const P = styled.p<PProps>(
  {
    cursor: 'default',
    lineHeight: '1.75',
    margin: 0,

    '&:not(:first-child)': {
      marginTop: '15px',
    },
  },
  ({ color, small = false, theme = anchoTheme }) => ({
    color: color ? theme.colors[color] : 'inherit',
    fontSize: small ? '1rem' : '1.125rem',
  }),
)
