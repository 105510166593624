import { FC, useEffect, useState } from 'react'
import { HeaderLink, HeaderWrapper } from './style'
import {
  FlexAlign,
  FlexJustify,
  LogoColor,
  ThemeColor,
  ThemeSize,
} from '@lib/theme'
import Container from '@components/core/container'
import Logo from '@components/core/logo'
import { useAncho } from '@context/ancho'
import { PrimaryButton } from '@components/core/buttons'

interface HeaderProps {
  contentColor?: ThemeColor
}

const Header: FC<HeaderProps> = ({ contentColor = ThemeColor.Primary }) => {
  const ancho = useAncho()

  const [scrolled, setScrolled] = useState<boolean>(false)

  const handleScroll = () => {
    if (window.scrollY > 75) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  return (
    <HeaderWrapper scrolled={scrolled}>
      <Container
        flexAlign={FlexAlign.Center}
        flexJustify={FlexJustify.SpaceBetween}
      >
        <Logo color={scrolled ? LogoColor.Primary : LogoColor[contentColor]} />
        {ancho && ancho.user ? (
          <HeaderLink href={`${process.env.APP_HOST}/`}>
            <PrimaryButton size={ThemeSize.Medium} style={{ marginTop: 0 }}>
              Dashboard
            </PrimaryButton>
          </HeaderLink>
        ) : (
          <HeaderLink href={`${process.env.APP_HOST}/signup`}>
            <PrimaryButton size={ThemeSize.Medium} style={{ marginTop: 0 }}>
              Sign up
            </PrimaryButton>
          </HeaderLink>
        )}
      </Container>
    </HeaderWrapper>
  )
}

export default Header
