import { FC } from 'react'
import { FooterWrapper } from './style'
import Container from '@components/core/container'
import Logo from '@components/core/logo'
import Block from '@components/core/block'
import anchoTheme, { LogoColor } from '@lib/theme'

const Footer: FC = () => {
  return (
    <FooterWrapper>
      <Container>
        <Block>
          <Logo color={LogoColor.White} height={30} />
        </Block>
      </Container>
      <Container>
        <p
          style={{
            color: anchoTheme.colors.grey,
            textAlign: 'center',
            width: '100%',
          }}
        >
          &copy; {new Date().getFullYear()} Ancho
        </p>
      </Container>
    </FooterWrapper>
  )
}

export default Footer
