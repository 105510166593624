import { NextPage } from 'next'
import Head from 'next/head'
import Link from 'next/link'
import {
  HiOutlineCode,
  HiOutlineKey,
  HiOutlineLightningBolt,
} from 'react-icons/hi'

import { FlexAlign, FlexDirection, ThemeColor, ThemeSize } from '@lib/theme'
import Page from '@components/page'
import Module from '@components/core/module'
import Container from '@components/core/container'
import { H1, H2, H4, H5 } from '@components/core/headings'
import { P } from '@components/core/paragraph'
import { PrimaryButton } from '@components/core/buttons'
import Block from '@components/core/block'
import Image from '@components/core/image'
import Card from '@components/core/card'
import { CodeBlock } from '@components/core/code-block'
import Highlight from 'react-highlight'
import Icon from '@components/core/icon'

const HomePage: NextPage = () => {
  return (
    <Page>
      <Head>
        <title>Ancho - MPC Wallets in a flash</title>
      </Head>
      <Module color={ThemeColor.Black} hero size={ThemeSize.Large} slantBottom>
        <Container
          flexAlign={FlexAlign.Center}
          mobileFlexDirecton={FlexDirection.ColumnReverse}
          tabletFlexDirecton={FlexDirection.ColumnReverse}
          topMargin
        >
          <Block mobileMarginTop tabletMarginTop>
            <H1>MPC wallets anywhere your users need them</H1>
            <P>
              Ancho offers a powerful set of SDKs to create and manage MPC
              wallets across multiple chains on mobile and web with low lift to
              your developers.
            </P>

            <PrimaryButton>Get started</PrimaryButton>
          </Block>
          <Block style={{ padding: '0 30px' }}>
            <Image src="/images/home-hero.svg" alt="Ancho hero" />
          </Block>
        </Container>
      </Module>
      <Module color={ThemeColor.Primary} slantTop>
        <Container flexAlign={FlexAlign.Center}>
          <Block>
            <CodeBlock>
              <Highlight>
                {`import Ancho from '@ancho/web'

const ancho = new Ancho({ userToken: 'YOUR_USER_TOKEN' })

const address = await ancho.getAddress('eip155:1')
const signature = await ancho.signMessage(
  'eip155:1', 
  'Hello, world!',
)`}
              </Highlight>
            </CodeBlock>
          </Block>
          <Block>
            <H2>Simple by design</H2>
            <P>
              Ancho's SDKs are built to be simple to use, easy to understand,
              and easily integrated into your existing application. With just a
              few lines of code, you're up and running with a fully functional
              MPC wallet.
            </P>

            <Link
              href={`${process.env.DOCS_HOST}/`}
              style={{
                display: 'block',
                marginTop: '30px',
                textDecoration: 'none',
              }}
            >
              <PrimaryButton
                color={ThemeColor.White}
                textColor={ThemeColor.Primary}
              >
                Read the docs
              </PrimaryButton>
            </Link>
          </Block>
        </Container>
      </Module>
      <Module color={ThemeColor.Light} size={ThemeSize.Large} slantBottom>
        <Container>
          <Block style={{ textAlign: 'center' }}>
            <H2>Managed and opinionated</H2>
            <P>
              We know that developers don't want to spend time deciding from a
              laundry list of options. Ancho is designed to be opinionated and
              managed, so you can focus on building your application, not
              managing your wallet infrastructure.
            </P>
          </Block>
        </Container>
        <Container style={{ marginTop: '30px' }}>
          <Block style={{ textAlign: 'center' }}>
            <Card>
              <Icon>
                <HiOutlineKey size={60} />
              </Icon>
              <H5>Self-custody backups</H5>
              <P small>
                Ancho backs up users' wallets using Passkeys from their device
                by default, so they don't have to worry about losing access to
                their wallets. No extra configuration or server-side setup
                required.
              </P>
            </Card>
          </Block>
          <Block style={{ textAlign: 'center' }}>
            <Card>
              <Icon>
                <HiOutlineCode size={60} />
              </Icon>
              <H5>Backend-less</H5>
              <P small>
                Ancho is designed to be used directly from the client, so you
                don't have to worry about managing a backend service to get
                wallets to your users. Just register a user, and start signing!
              </P>
            </Card>
          </Block>
          <Block style={{ textAlign: 'center' }}>
            <Card>
              <Icon>
                <HiOutlineLightningBolt size={60} />
              </Icon>
              <H5>Optimized for the client</H5>
              <P small>
                Ancho wallets are pre-generated, so as soon as you register a
                new user, they already have a wallet. No need to wait for
                blockchain confirmations or wallet generation.
              </P>
            </Card>
          </Block>
        </Container>
        <Container>
          <Block>
            <Link
              href={`${process.env.APP_HOST}/signup`}
              style={{
                display: 'block',
                marginTop: '30px',
                textAlign: 'center',
                textDecoration: 'none',
              }}
            >
              <PrimaryButton>Try it out</PrimaryButton>
            </Link>
          </Block>
        </Container>
      </Module>
      <Module color={ThemeColor.Black} slantTop>
        <Container>
          <Block>
            <H2>Fully self-service</H2>
            <P>
              Our dashboard gives you full control over your Ancho account. You
              can sign up, create and manage your wallets, and monitor your
              usage all from one place.
            </P>
            <P>
              Our SDKs are public, so you can get started right away. No need to
              wait for approval or a sales call.
            </P>

            <Link
              href={`${process.env.APP_HOST}/signup`}
              style={{
                display: 'block',
                marginTop: '30px',
                textDecoration: 'none',
              }}
            >
              <PrimaryButton>Get started</PrimaryButton>
            </Link>
          </Block>
          <Block />
        </Container>
      </Module>
      <Module color={ThemeColor.White}>
        <Container></Container>
      </Module>
      <Module color={ThemeColor.Primary}>
        <Container style={{ textAlign: 'center' }}>
          <Block>
            <H4>Ready to get started?</H4>
            <P>
              This is where everyone else would ask you to schedule a demo, but
              we're not everyone else. We're Ancho. We're ready when you are.
            </P>
            <Link
              href={`${process.env.APP_HOST}/signup`}
              style={{
                display: 'block',
                marginTop: '30px',
                textDecoration: 'none',
              }}
            >
              <PrimaryButton
                color={ThemeColor.White}
                textColor={ThemeColor.Primary}
              >
                Sign up
              </PrimaryButton>
            </Link>
          </Block>
        </Container>
      </Module>
    </Page>
  )
}

export default HomePage
