import { Theme } from '@emotion/react'

export enum FlexAlign {
  Center = 'center',
  FlexEnd = 'flex-end',
  FlexStart = 'flex-start',
  Stretch = 'stretch',
}

export enum FlexDirection {
  Column = 'column',
  ColumnReverse = 'column-reverse',
  Row = 'row',
  RowReverse = 'row-reverse',
}

export enum FlexJustify {
  Center = 'center',
  FlexEnd = 'flex-end',
  FlexStart = 'flex-start',
  SpaceAround = 'space-around',
  SpaceBetween = 'space-between',
  SpaceEvenly = 'space-evenly',
}

export enum FlexWrap {
  NoWrap = 'nowrap',
  Wrap = 'wrap',
}

export enum LogoColor {
  Black = 'black',
  Dark = 'dark',
  Grey = 'grey',
  Light = 'light',
  Primary = 'primary',
  White = 'white',
}

export enum ThemeColor {
  Black = 'black',
  Light = 'light',
  Primary = 'primary',
  White = 'white',
}

export enum ThemeSize {
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
}

const anchoTheme: Theme = {
  colors: {
    black: '#111212',
    dark: '#2B2E2E',
    grey: '#A4AAA6',
    light: '#F3F8F5',
    primary: '#FF3568',
    white: '#ffffff',
  },
}

export default anchoTheme
