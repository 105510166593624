import styled from '@emotion/styled'
import { mediaQueries } from '@lib/media-queries'

interface BlockProps {
  marginTop?: boolean
  mobileMarginTop?: boolean
  tabletMarginTop?: boolean
}

const Block = styled.div<BlockProps>(
  {
    display: 'block',
    flexGrow: 1,
    maxWidth: '100%',
    width: '100%',

    [`${mediaQueries.desktop}`]: {
      '&:not(:first-of-type)': {
        marginLeft: '15px',
      },
      '&:not(:last-of-type)': {
        marginRight: '15px',
      },
    },

    [`${mediaQueries.largeDesktop}`]: {
      '&:not(:first-of-type)': {
        marginLeft: '15px',
      },
      '&:not(:last-of-type)': {
        marginRight: '15px',
      },
    },
  },
  ({
    marginTop = false,
    mobileMarginTop = false,
    tabletMarginTop = false,
  }) => ({
    marginTop: marginTop ? '30px' : '0',

    [`${mediaQueries.mobile}`]: {
      marginTop: mobileMarginTop || marginTop ? '30px' : '0',
    },
    [`${mediaQueries.tablet}`]: {
      marginTop: tabletMarginTop || marginTop ? '30px' : '0',
    },
  }),
)

export default Block
